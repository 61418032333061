import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "tv-auto-transcribe",
    path: "/tv-auto-transcribe",
    component: () => import("pages/AutoTranscribe.vue"),
    props: (route) => ({ medium: "TV", ...route.params, ...route.query }),
    meta: {
      title: "Auto Transcribe - TV",
    },
  },
  {
    name: "tv-items-list",
    path: "/tv_items",
    component: () => import("pages/tv/TvItemsList.vue"),
    props: true,
    meta: {
      title: "TV Items",
    },
  },
  {
    name: "tv-caption-new",
    path: "/tv_caption/new",
    component: () => import("pages/tv/TvCaptionNew.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "New TV Caption",
    },
  },
  {
    name: "tv-caption-edit",
    path: "/tv_caption/:id/edit",
    component: () => import("pages/tv/TvCaptionEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Caption",
    },
  },
  {
    name: "tv-super-new",
    path: "/tv_super/new",
    component: () => import("pages/tv/TvSuperNew.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "New TV Super",
    },
  },
  {
    name: "tv-super-edit",
    path: "/tv_super/:id/edit",
    component: () => import("pages/tv/TvSuperEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Super",
    },
  },
  {
    name: "tv-channels-list",
    path: "/tv-channels",
    component: () => import("pages/tv/TvChannelList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "TV Channels",
    },
  },
  {
    name: "tv-channels-new",
    path: "/tv-channels/new",
    component: () => import("pages/tv/TvChannelEdit.vue"),
    meta: {
      title: "New TV Channel",
    },
  },
  {
    name: "tv-channels-edit",
    path: "/tv-channels/:id/edit",
    component: () => import("pages/tv/TvChannelEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Channel",
    },
  },
  {
    name: "tv-program-categories-list",
    path: "/tv-program-categories",
    component: () => import("pages/tv/TvProgramCategoryList.vue"),
    props: true,
    meta: {
      title: "TV Program Categories",
    },
  },
  {
    name: "tv-program-categories-new",
    path: "/tv-program-categories/new",
    component: () => import("pages/tv/TvProgramCategoryEdit.vue"),
    meta: {
      title: "New TV Program Category",
    },
  },
  {
    name: "tv-program-categories-edit",
    path: "/tv-program-categories/:id/edit",
    component: () => import("pages/tv/TvProgramCategoryEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Program Category",
    },
  },
  {
    name: "tv-channels-schedule",
    path: "/tv-channels/:id/schedule",
    component: () => import("pages/tv/TvChannelSchedule.vue"),
    props: true,
    meta: {
      title: "TV Channel Schedule",
    },
  },
  {
    name: "tv-programs-list",
    path: "/tv_programs",
    component: () => import("pages/TvProgramList.vue"),
    props: true,
    meta: {
      title: "TV Programs",
    },
  },
  {
    name: "tv-programs-new",
    path: "/tv_programs/new",
    component: () => import("pages/TvProgramEdit.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "New TV Program",
    },
  },
  {
    name: "tv-programs-edit",
    path: "/tv_programs/:id",
    component: () => import("pages/TvProgramEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Program",
    },
  },
  {
    name: "tv-player",
    path: "/tv-player",
    component: () => import("pages/tv/TvPlayerPage.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "TV Player",
    },
  },
  {
    name: "tv-media-requests-new",
    path: "/tv-media-requests/new",
    component: () => import("pages/TvMediaRequestEdit.vue"),
    meta: {
      title: "New TV Media Item",
    },
  },
  {
    name: "tv-media-requests-edit",
    path: "/tv-media-requests/:tvMediaRequestId/edit",
    component: () => import("pages/TvMediaRequestEdit.vue"),
    props: true,
    meta: {
      title: "Edit TV Media Item",
    },
  },
  {
    name: "tv-clips",
    path: "/tv-clips",
    component: () => import("pages/tv/TvClipList.vue"),
    props: true,
    meta: {
      accessLevel: "content_removal",
      title: "TV Clips",
    },
  },
];
