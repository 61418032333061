import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "radio-auto-transcribe",
    path: "/radio-auto-transcribe",
    component: () => import("pages/AutoTranscribe.vue"),
    props: (route) => ({ medium: "Radio", ...route.params, ...route.query }),
    meta: {
      title: "Auto Transcribe - Radio",
    },
  },
  {
    name: "radio-stations-list",
    path: "/radio-stations",
    component: () => import("pages/RadioStationList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Radio Stations",
    },
  },
  {
    name: "radio-stations-new",
    path: "/radio-stations/new",
    component: () => import("pages/RadioStationEdit.vue"),
    meta: {
      title: "New Radio Station",
    },
  },
  {
    name: "radio-stations-edit",
    path: "/radio-stations/:id",
    component: () => import("pages/RadioStationEdit.vue"),
    props: true,
    meta: {
      title: "Edit Radio Station",
    },
  },
  {
    name: "radio-stations-schedule",
    path: "/radio-stations/:id/schedule",
    component: () => import("pages/RadioStationSchedule.vue"),
    props: true,
    meta: {
      title: "Edit Radio Station",
    },
  },
  {
    name: "radio-clips-list",
    path: "/radio-clips",
    component: () => import("pages/RadioClipList.vue"),
    props: true,
    meta: {
      title: "Radio Clips",
    },
  },
  {
    name: "radio-programs-list",
    path: "/radio-programs",
    component: () => import("pages/RadioProgramList.vue"),
    props: ({ query }) => ({ query }),
    meta: {
      title: "Radio Programs",
    },
  },
  {
    name: "radio-programs-new",
    path: "/radio-programs/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/RadioProgramEdit.vue"),
    meta: {
      title: "New Radio Program",
    },
  },
  {
    name: "radio-programs-edit",
    path: "/radio-programs/:id",
    component: () => import("pages/RadioProgramEdit.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "Edit Radio Program",
    },
  },
  {
    name: "radio-player",
    path: "/radio-player",
    component: () => import("pages/radio/RadioPlayerPage.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "Radio Player",
    },
  },
  {
    name: "radio-media-requests-new",
    path: "/radio-media-requests/new",
    component: () => import("pages/RadioMediaRequestEdit.vue"),
    meta: {
      title: "New Radio Media Item",
    },
  },
  {
    name: "radio-media-requests-edit",
    path: "/radio-media-requests/:radioMediaRequestId/edit",
    component: () => import("pages/RadioMediaRequestEdit.vue"),
    props: true,
    meta: {
      title: "Edit Radio Media Item",
    },
  },
  {
    name: "radio-clips",
    path: "/radio-clips-list",
    component: () => import("pages/radio/RadioClipList.vue"),
    props: true,
    meta: {
      accessLevel: "content_removal",
      title: "Radio Clips List",
    },
  },
];
