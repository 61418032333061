import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";
import type { Nullable } from "shared/types";

import TvChannel from "./TvChannel";
import TvProgramAiring from "./TvProgramAiring";

@Model()
class TvClip extends ApplicationRecord {
  static jsonapiType = "tv_clips";

  @BelongsTo() source: TvChannel;

  @BelongsTo() programAiring: TvProgramAiring;

  @Attr({ persist: false }) channelId: number;

  @Attr({ persist: false }) startTime: number;

  @Attr({ persist: false }) endTime: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) url: string;

  @Attr({ persist: false }) tvProgramAiringId: number;

  @Attr() archivedAt: Nullable<string>;
}

export default TvClip;
