import { DAY_IN_MILLISECONDS } from "shared/constants";
import store from "shared/services/localForageInstance";
import StorageService from "shared/services/StorageService";

export const cacheOrganisationToken = (token, organisationId) =>
  StorageService.set(
    `token:${organisationId}`,
    JSON.stringify({
      validUntil: Date.now() + 30 * DAY_IN_MILLISECONDS,
      token,
    })
  );

export const cacheOrganisationRefreshToken = (refreshToken, organisationId) =>
  StorageService.set(
    `refreshToken:${organisationId}`,
    JSON.stringify({
      validUntil: Date.now() + 30 * DAY_IN_MILLISECONDS,
      refreshToken,
    })
  );

async function setCachedToken(token, organisationId) {
  const organisationKey =
    organisationId ||
    (await StorageService.get("organisationId", sessionStorage));

  if (organisationKey) {
    await StorageService.set("organisationId", organisationKey, sessionStorage);
    await cacheOrganisationToken(token, organisationKey);
  }

  return StorageService.set("token", token);
}

async function setCachedRefreshToken(refreshToken, organisationId) {
  const organisationKey =
    organisationId ||
    (await StorageService.get("organisationId", sessionStorage));

  if (organisationKey) {
    await StorageService.set("organisationId", organisationKey, sessionStorage);
    await cacheOrganisationRefreshToken(refreshToken, organisationKey);
  }

  return StorageService.set("refreshToken", refreshToken);
}

async function expireCachedTokens() {
  const removalPromises = [];

  store.iterate((value, key) => {
    if (key.includes("token:")) {
      try {
        const expiry = JSON.parse(value)?.validUntil;

        if (expiry && expiry < Date.now()) {
          removalPromises.push(StorageService.remove(key, store));
        }
      } catch {
        // do nothing
      }
    }
  });

  return Promise.allSettled(removalPromises);
}

async function expireCachedRefreshTokens() {
  const removalPromises = [];

  store.iterate((value, key) => {
    if (key.includes("refreshToken:")) {
      try {
        const expiry = JSON.parse(value)?.validUntil;

        if (expiry && expiry < Date.now()) {
          removalPromises.push(StorageService.remove(key, store));
        }
      } catch {
        // do nothing
      }
    }
  });

  return Promise.allSettled(removalPromises);
}

export const isAdminUser = async () => {
  try {
    const accountManager =
      JSON.parse(await StorageService.get("accountManager")) || {};

    return Boolean(Object.keys(accountManager).length);
  } catch {
    return false;
  }
};

export const cachedToken = async (organisationId) => {
  try {
    const { token, validUntil } =
      JSON.parse(await StorageService.get(`token:${organisationId}`)) || {};

    if (!token || !validUntil) return null;

    if (validUntil <= Date.now()) return null;

    return token;
  } catch {
    return null;
  }
};

export const clearCachedTokens = async () => {
  StorageService.remove("token");
  StorageService.remove("token", sessionStorage);

  const removalPromises = [];

  store.iterate((_value, key) => {
    if (key.includes("token:")) {
      removalPromises.push(StorageService.remove(key, store));
    }
  });

  return Promise.allSettled(removalPromises);
};

export const updateUserToken = async (token, organisationId) => {
  expireCachedTokens();

  if (!(await isAdminUser())) {
    await setCachedToken(token, organisationId);
  }

  await StorageService.set("token", token, sessionStorage);
};

export const updateUserRefreshToken = async (refreshToken, organisationId) => {
  expireCachedRefreshTokens();

  if (!(await isAdminUser())) {
    await setCachedRefreshToken(refreshToken, organisationId);
  }

  await StorageService.set("refreshToken", refreshToken, sessionStorage);
};

export const loadOrganisationToken = async () => {
  const organisationId = await StorageService.get("newOrgId", sessionStorage);

  if (organisationId) {
    const orgToken = await cachedToken(organisationId);

    if (!orgToken) {
      StorageService.remove("newOrgId", sessionStorage);

      return;
    }

    await Promise.all([
      StorageService.set("token", orgToken, sessionStorage),
      StorageService.set("organisationId", organisationId, sessionStorage),
      StorageService.remove("newOrgId", sessionStorage),
    ]);
  }
};

export default {
  cachedToken,
  cacheOrganisationToken,
  clearCachedTokens,
  isAdminUser,
  loadOrganisationToken,
  updateUserToken,
};
