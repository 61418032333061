import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class ArticleRankingSource extends ApplicationRecord {
  static jsonapiType = "article_ranking_sources";

  @Attr() name: string;

  @Attr() url: string;

  @Attr() group: string;

  @Attr() region: string;

  @Attr() logoUrl: string;

  @Attr() logoData: string;

  @Attr() enabled: boolean;

  @Attr() section: string;

  @Attr() blockAds: boolean;

  @Attr() emulateMobile: boolean;

  @Attr() defaultEnabled: boolean;

  @Attr({ persist: false }) createdAt: string;
}

export default ArticleRankingSource;
