import { Attr, BelongsTo, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import RadioProgramAiring from "./RadioProgramAiring";
import RadioStation from "./RadioStation";

@Model()
class RadioClip extends ApplicationRecord {
  static jsonapiType = "radio_clips";

  @BelongsTo() source: RadioStation;

  @BelongsTo() programAiring: RadioProgramAiring;

  @HasOne() syndicatedRadioClip: RadioClip;

  @Attr({ persist: false }) stationId: number;

  @Attr({ persist: false }) radioProgramAiringId: number;

  @Attr({ persist: false }) syndicationId: number;

  @Attr({ persist: false }) programId: number;

  @Attr({ persist: false }) audienceBlockId: number;

  @Attr({ persist: false }) copyrightLabelId: number;

  @Attr({ persist: false }) ucpId: string;

  @Attr({ persist: false }) ucpPublicationProvidedId: string;

  @Attr({ persist: false }) startTime: number;

  @Attr({ persist: false }) endTime: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) transcribedAt: string;

  @Attr({ persist: false }) text: string;

  @Attr({ persist: false }) url: string;

  @Attr({ persist: false }) speechPercentage: number;

  @Attr({ persist: false }) cisionSource: string;

  @Attr({ persist: false }) audience: number;

  @Attr({ persist: false }) advertisementRate: number;

  @Attr({ persist: false }) utcdeltaMinutes: number;

  @Attr({ persist: false }) advertisementRateCurrency: string;

  @Attr({ persist: false }) languageCode: string;
}

export default RadioClip;
